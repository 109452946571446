/** @jsx jsx */
import { jsx } from "theme-ui"

const Logo = (props) => (
  <svg
    sx={{
      height: props.size,
      width: props.size,
    }}
    viewBox="0 0 576 512"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      {/*<g>
        <path d = "M448 64v416h-336c-26.513 0-48-21.49-48-48s21.487-48 48-48h304v-384h-320c-35.199 0-64 28.8-64 64v384c0 35.2 28.801 64 64 64h384v-448h-32z"
        fill={props.color}></path>
        <path d = "M112.028 416v0c-0.009 0.001-0.019 0-0.028 0-8.836 0-16 7.163-16 16s7.164 16 16 16c0.009 0 0.019-0.001 0.028-0.001v0.001h303.945v-32h-303.945z"
        fill={props.color}></path>
      </g>*/}
      <g>
        <path
          d = "M228.5 511.8l-25-7.1c-3.2-.9-5-4.2-4.1-7.4L340.1 4.4c.9-3.2 4.2-5 7.4-4.1l25 7.1c3.2.9 5 4.2 4.1 7.4L235.9 507.6c-.9 3.2-4.3 5.1-7.4 4.2zm-75.6-125.3l18.5-20.9c1.9-2.1 1.6-5.3-.5-7.1L49.9 256l121-102.5c2.1-1.8 2.4-5 .5-7.1l-18.5-20.9c-1.8-2.1-5-2.3-7.1-.4L1.7 252.3c-2.3 2-2.3 5.5 0 7.5L145.8 387c2.1 1.8 5.3 1.6 7.1-.5zm277.3.4l144.1-127.2c2.3-2 2.3-5.5 0-7.5L430.2 125.1c-2.1-1.8-5.2-1.6-7.1.4l-18.5 20.9c-1.9 2.1-1.6 5.3.5 7.1l121 102.5-121 102.5c-2.1 1.8-2.4 5-.5 7.1l18.5 20.9c1.8 2.1 5 2.3 7.1.4z"
          fill={props.color}
        ></path>
      </g>
    </g>
  </svg>
)

Logo.defaultProps = {
  color: "white",
}
export default Logo
